const initSliderHero = () => {
  const sliders = document.querySelectorAll('.slider-hero');

  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const container = slider.querySelector('.slider-hero__container');
    const prevBtn = slider.querySelector('.slider-btn--prev');
    const nextBtn = slider.querySelector('.slider-btn--next');
    const pagination = slider.querySelector('.slider-hero__pagination');

    const swiper = new Swiper(container, {
      autoplay: {
        delay: 5000,
      },
      loop: true,
      grabCursor: true,
      mousewheel: {
        forceToAxis: true,
      },
      navigation: {
        prevEl: prevBtn,
        nextEl: nextBtn,
      },
      pagination: {
        el: pagination,
        type: 'fraction',
      },
    });

  });
};

export {initSliderHero};
