const breakpoint = window.matchMedia('(min-width: 1024px)');

const initMobileMenu = () => {
  const mainNav = document.querySelector('.main-nav');

  if (!mainNav) {
    return;
  }

  const mainNavToggle = document.querySelector('.main-nav__toggle');
  const mainNavOverlay = mainNav.querySelector('.main-nav__overlay');

  const onEscPress = (evt) => {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      evt.preventDefault();

      closeMenu();
    }
  };

  const openMenu = () => {
    mainNav.classList.add('is-open');

    window.scrollLock.disableScrolling();

    document.addEventListener('keydown', onEscPress);
  };

  const closeMenu = () => {
    mainNav.classList.remove('is-open');

    window.scrollLock.enableScrolling();

    document.removeEventListener('keydown', onEscPress);
  };

  const breakpointChecker = () => {
    if (breakpoint.matches && mainNav.classList.contains('is-open')) {
      closeMenu();
    }
  };

  const onMainNavToggleClick = () => {
    if (mainNav.classList.contains('is-open')) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const onMainNavOverlayClick = () => {
    if (mainNav.classList.contains('is-open')) {
      closeMenu();
    }
  };

  breakpoint.addListener(breakpointChecker);
  breakpointChecker();

  mainNavToggle.addEventListener('click', onMainNavToggleClick);
  mainNavOverlay.addEventListener('click', onMainNavOverlayClick);
};

export {initMobileMenu};
