const breakpoint374 = window.matchMedia('(max-width: 374px');
const breakpoint599 = window.matchMedia('(max-width: 599px');
const sliders = document.querySelectorAll('.slider-product-gallery');
const modalSlider = document.querySelector('.slider-modal-photos');

let modalSwiper;
let modalActiveSlide = 0;

let modalContainer;
let modalPagination;
let modalPrevBtn;
let modalNextBtn;

if (modalSlider) {
  modalContainer = modalSlider.querySelector('.slider-modal-photos__container');
  modalPagination = modalSlider.querySelector('.slider-modal-photos__pagination');
  modalPrevBtn = modalSlider.querySelector('.slider-btn--prev');
  modalNextBtn = modalSlider.querySelector('.slider-btn--next');
}

const initModalSwiper = () => {
  if (modalSlider) {
    modalSwiper = new Swiper(modalContainer, {
      initialSlide: modalActiveSlide,
      loop: true,
      mousewheel: {
        forceToAxis: true,
      },
      navigation: {
        prevEl: modalPrevBtn,
        nextEl: modalNextBtn,
      },
      pagination: {
        el: modalPagination,
        bulletClass: 'slider-modal-photos__bullet',
        bulletActiveClass: 'slider-modal-photos__active-bullet',
      },
    });
  }
};

const destroyModalSwiper = () => {
  modalActiveSlide = 0;

  setTimeout(() => {
    if (modalSwiper) {
      modalSwiper.destroy(true, true);
    }
  }, 300);
};

const initSliderProductGallery = () => {
  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const wrapper = slider.querySelector('.slider-product-gallery__photos-wrapper');
    const thumbs = slider.querySelector('.slider-product-gallery__thumbs');
    const photos = slider.querySelector('.slider-product-gallery__photos');
    const prevBtn = slider.querySelector('.slider-product-gallery__btn--prev');
    const nextBtn = slider.querySelector('.slider-product-gallery__btn--next');
    const thumbSlides = thumbs.querySelectorAll('.slider-product-gallery__thumbs-slide');

    const isNeedLoop = thumbSlides.length > 6 ? true : false;

    if (!isNeedLoop) {
      prevBtn.style.display = 'none';
      nextBtn.style.display = 'none';
    }

    let thumbsSwiper;
    let photosSwiper;

    const initSwipers = () => {
      thumbsSwiper = new Swiper(thumbs, {
        direction: 'horizontal',
        loop: isNeedLoop,
        spaceBetween: 4,
        slidesPerView: 6,
        loopedSlides: 6,
        mousewheel: {
          forceToAxis: true,
        },
        navigation: isNeedLoop ? {
          nextEl: nextBtn,
          prevEl: prevBtn,
        } : {},
        breakpoints: {
          375: {
            spaceBetween: 7,
          },
          600: {
            direction: 'vertical',
            spaceBetween: 10,
          },
        },
      });

      photosSwiper = new Swiper(photos, {
        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        allowTouchMove: false,
        thumbs: {
          swiper: thumbsSwiper,
        },
      });
    };

    const breakpointChecker = () => {
      thumbsSwiper.destroy(true, true);
      photosSwiper.destroy(true, true);
      initSwipers();
    };

    const onSlideClick = (evt) => {
      const target = evt.target.closest('.slider-product-gallery__photos-slide');
      if (target) {
        modalActiveSlide = Number(target.dataset.swiperSlideIndex);
      }
    };

    initSwipers();

    breakpoint374.addListener(breakpointChecker);
    breakpoint599.addListener(breakpointChecker);

    wrapper.addEventListener('click', onSlideClick);
  });
};


export {initSliderProductGallery, initModalSwiper, destroyModalSwiper};
